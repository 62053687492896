.bar{
    &__item{
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        align-items: center;
        border-radius: 8px 21px;
        padding:0 1vw ;
        transform: translate(0px, 0px);
        transition:  background-color 0.4s ease-out, fill  0.4s ease-out, transform 0.4s ease-out;
        &:hover .bar__icon{
            background-color: $color-main;
            fill: $color-extra;
        }
        &:hover{
            transform:translate(3px, -13px);
            @include mq($until: sm) {
                transform:translate(0px, -5px);
            }
        }

    }
    &__icon{
        fill: #a0445b;
        width: 100px;
        height: 100px;
        border-radius: 90px 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 16px 0;
        box-shadow: 0px 0px 13px 5px rgba(0,0,0,0.03);
        transition:  background-color 0.3s ease-out, fill 0.3s ease-out ;
        background-color: #fff;
        @include mq($from: xl) {
            width: 80px;
            height: 80px;
         }
        svg{
            width:50px;
        }

    }
    &__title{
      font-size: rem(14px);  
    }
}