
.subsite{
    &__top-foto{
        background-image: url('../../images/sub-top.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height:34.3vw;
        width: 100%;
        background-color: $color-extra;
        background-blend-mode: multiply;
    }
    &__wrapper{
        display: flex;
        justify-content: center;
        background-color: $color-extra;
    }
    &__content{
        position: relative;
        top: -8vw;
        max-width:70%;
        border-radius: 21px;
        padding: 3vw;
        background-color: #fff;
        box-shadow: 0px 0px 13px 5px rgba(0,0,0,0.03);
        @include mq($until: xl) {
            min-width: 80%;
        }
        @include mq($until: sm) {
            max-width: 90%;
            border-radius: 13px;
            position: static;
            margin-top: 21px;
        }

        @include mq($until: xs) {
            max-width: 96%;
            border-radius: 8px;
         }
    }
    &__links{
        height: 100%;
        position: relative;
        top: 21px;
        margin-bottom: 138px;
        position: -webkit-sticky;
        position: sticky;
        @include mq($until: sm) {
            display: none;
        }
    }
}
.subsite__header-container{

}