// =========================
// Fonts
// =========================

 @font-face {
     font-family: 'Montserrat';
     font-weight: 300;
     src: url('../../fonts/Montserrat-Light.ttf');
 }
@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('../../fonts/Montserrat-SemiBold.ttf');
}
