.footer{
  height: auto;
  padding-top: 55px;
  background-color: $color-extra;
  position: relative;
  &__nav{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 34px;
    @include mq($until: md) {
      margin-bottom: 0;
    }
    @include mq($until: sm) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__wrapper{
    z-index: 5;
    background-image: url('../../images/footer-bg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
  &__block{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 6vw;
    height: 20.8vw;
    @include mq($until: md) {
      padding-top: 45px;
      height: auto;
      background-color: $color-main;
      justify-content: space-around;
    }
    @include mq($until: sm) {
      flex-direction: column;
      height: auto;
      background-color: $color-main;
      align-items: center;
    }
  }
  &__item{
    padding: 0 13px 0 13px ;
    transition: color 0.4s ease-out;
    @include mq($until: md) {
      text-align: center;
      justify-content: center;
    }
    @include mq($until: sm) {
      margin-bottom: 21px;  
    }
    a, p{
      color: #fff; 
      line-height: 1.5;
      @include mq($until: lg) {
        font-size: rem(14px);
        // line-height: 0.8;
      }
    }
    a:hover{
      color: $color-hover;
    }
  }
  &__item-name{
    display: flex;
    align-items: center;
    margin-bottom: 2vw;
    @include mq($until: md) {
      margin-bottom: 13px;
      justify-content: center;

    }
    @include mq($until: sm) {
      flex-direction: column;
      align-items: center;
    }
    h4{
      margin: unset;
      padding-left: 13px;
      color: #fff;
      font-size: rem(18px);
      @include mq($until: md) {
        font-size: rem(16px);
      }
      @include mq($until: sm) {
        padding-left: 0;  
      }
    }
    svg{
      fill: #fff; 
      @include mq($from: lg) {
        width: 34px;
      }
      @include mq($until: md) {
        width: 30px;
      }
      @include mq($until: sm) {
        width: 30px;
        margin-bottom: 8px;  
      }
    }
  }
  &__section{
    display: flex;
    align-items: center;
    @include mq($until: md) {
      justify-content: center;  
    }
    @include mq($until: sm) {
      display: flex;
      flex-direction: column;
    }
    a{
      padding-left: 13px;
      @include mq($until: sm) {
        padding-left: 0;
        padding: 13px;
      }
    }
    svg{
      fill: #fff; 
    }
  }
  &__data{
    position: absolute;
    z-index:1;
    bottom: 5vw;
  }
  &__icon svg{
    width: 28px;
    margin: 0 7px 0 7px;
    height: auto;
    @include mq($until: md) {
      width: 21px;  
      margin: 0 3px 0 3px;
      height: auto;
    }
  }
  &__content{
    margin-bottom: 13px; 
    @include mq($until: md) {
      margin-bottom: 5px; 
    }   
      a{
        display: flex;
        flex-direction: column;
        display: inline-block;
        margin-bottom: 5px;
      }
      &--phones{
        @include mq($until: sm) {
          display: flex;
          flex-direction: column;
        }
      }
  }

  &__info{
    @include mq($until: md) {
     padding-left: unset;
     padding-bottom: 8px;
    }
  }
  &__menu .header__menuLink {
      color: $color-main;
      transition: color 0.4s ease-out;
      &:hover{
      color: $color-hover;
      }
      @include mq($until: md) {
        font-size: rem(16px);
        margin-right: 13px;
      }
  }
  &__nav .header__logo {
    @include mq($until: md) {
      width: 260px;
    }
  }
  &__menu .header__menuList {
    @include mq($until: md) {
      flex-direction: row;
    }
  }
  &__optional{
    padding-top: 34px;
    width: 100%;
    background-color: $color-main;
    display: flex;
    justify-content: space-between;
    @include mq($until: md) {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom:21px;
    }
    @include mq($until: sm) {
      display: flex;
      flex-direction: column;  
      align-items: center;
      text-align: center;
    }
  }
  &__container{
    display: flex;
    align-items: baseline;
    height: auto;
    @include mq($until: md) {
     padding-right: unset; 
    }
    @include mq($until: sm) {
      flex-direction: column;
      align-items: center;
    }
    p{
      font-weight: 700;
      letter-spacing: 0.5px;
    }


  }
}
.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }
}