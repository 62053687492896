.home{
    @include mq($from: xxl) {
        max-width: 1919px;
        margin-right: auto;
        margin-left: auto;
    }
}
.main-h1{
    font-size: rem(32px);
    letter-spacing: -0.5px;
    color: $color-main; 
    margin-bottom: unset;
    @include mq($until: xl) {
        font-size: rem(28px);
    }
    
}
.main-h2{
    color: $color-main;
    @include mq($until: lg) {
        font-size: rem(26px);
    }
}
.top{
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    .main-h1{
        @include mq($until: sm) {
            text-align: center;
            margin-top: 50px;
         }
        @include mq($until: sm) {
            margin-top: 21px;
         }
    }
    &__bg{
        position: absolute;
        z-index: -1;
        bottom: -39vw;
        height: 48vw;
        width: 100%;
        height: auto;
        fill:$color-extra;
        @include mq($until: xl) {
            bottom: -44vw;
        }
        @include mq($until: md) {
            bottom: -47vw;
        }
        @include mq($until: sm) {
            bottom: -2vw;
        }
        @include mq($until: xs) {
            bottom: -46vw;  
        }
    }
    @include mq($until: sm) {
       flex-direction:column-reverse;
       align-items: center;
    }
    &__wrapper{
        margin-top: auto;
        margin-bottom: 6.5vw;
        max-width: 50%;
        @include mq($until: xl) {
            margin-bottom: 2vw;
        }
        @include mq($until: lg) {
            margin-bottom: unset;
            margin-top: 5vw;
        }
        @include mq($until: sm) {
            max-width: unset;
            width: 80%;
            padding-left: unset; 
        }

    }
    &__list{
        display: flex;
        flex-direction: column;
        margin-top: 2vw;
        @include mq($until: sm) {
         flex-direction: row;  
         justify-content: space-between; 
         align-items: center;
        }
        @include mq($until: xs) {
            flex-direction: column;
        }
    }
    &__rwd{
        @include mq($until: sm) {
            max-width: 65%;
        }
        @include mq($until: xs) {
            max-width: 100%;
         } 
    }
    &__bulletpoint{
        display: flex;
        align-items: center;   
        svg{
            fill: $color-main;
            padding-right:13px;
            transition: fill 0.4s ease-out
        } 
        @include mq($until: xl) {
            svg{
                width: 34px;
            }
        } 
        @include mq($until: xs) {
            svg{
                width: 28px;
            }
        }      
        &:hover svg{
            fill: $color-hover;
        }
        &:hover .top__link{
            color:$color-hover;
        }
    }
    &__link{
        font-size: rem(24px);
        margin:13px 0;
        transition: color 0.4s ease-out;
        font-size: rem(24px);
        @include mq($until: xl) {
            margin: 0; 
            font-size: rem(22px);    
        }
        @include mq($until: md) {
            font-size: rem(20px);
            line-height: 1;    
        }
    }
    &__contact{
        margin-top: 3vw;
        @include mq($until: xs) {
            margin-top: 5vw;
            width: 100%;   
        }
        p{
            margin-top: 21px;
            text-align: center;
            font-weight: 500;
            @include mq($until: md) {
               display: none;
            }
        }

        @include mq($until: xl) {
            margin-top: 2vw;
        }
    }
    &__button{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 21px;
        background-color: $color-main;
        color: #fff; 
        border-radius: 5px;
        padding:21px 13px;
        width: auto;
        font-size: rem(21px);


        transition: background-color 0.4s ease-out, color 0.4s ease-out;
        &:hover{
         background-color: $color-hover;
         color: #fff; 
        }
        p{
            padding-top: 13px;
        }
        @include mq($until: xl) {
            padding:13px 8px;
        }
        @include mq($until: md) {
            text-indent: -1000em; 
         }
        @include mq($until: sm) {
            width: auto;
            flex-direction: column;
            border-radius: 90px 50px;
            font-size: 0;
            width: 110px;
        }
        @include mq($until: xs) {
            width: auto;
            border-radius: 5px;
            flex-direction: row;
            text-indent: unset;
            font-size: 14px;
        }
        img{
            margin: 0 21px;
            width: 34px;
            @include mq($until: xl) {
                width: 28px;
                margin: 0 13px;
            }
            @include mq($until: sm) {
                width: 45px;
            }
            @include mq($until: xs) {
                width: 26px;
                margin: 0 5px 0 13px;
            }
        }
    }
    &__image{
        width: 49%;
        height: auto; 
        @include mq($until: md) {
            width:48%;
            margin-left: auto;
        } 
        @include mq($until: sm) {
            width: 98%;
            margin-left: auto;
        }      
    }
}
.sek1{
    padding-top: 150px;
    background-color:$color-extra;
    text-align: center;
    padding-bottom: 80px;
    @include mq($until: lg) {
        padding-top: 90px;
    }
    @include mq($until: md) {
        padding-bottom: 34px;
    }
    @include mq($until: xs) {
        padding-top: 55px;
    }
    h2{
        margin-bottom: 70px;
        @include mq($until: xl) {
            margin-bottom: 55px;
        }
        @include mq($until: xs) {
            margin-bottom: 34px;
        }
    }

    &__wrapper{
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(4, 1fr);
        grid-gap:2vw;
        position: absolute;
        z-index: 5;
        @include mq($until: md) {
            grid-template-columns: repeat(2, 1fr);
            position: static;
        }
        @include mq($until: xs) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 15px;
        }
    }
    &__item{
        background-color: #fff;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        align-items: center;
        border-radius: 8px 21px;
        box-shadow: 0px 0px 13px 5px rgba(0,0,0,0.03);
        padding: 2vw 2vw ;
        min-height: 420px;
        // top: 0;
        transform: translate(0px, 0px);
        transition:  background-color 0.4s ease-out, fill  0.4s ease-out, transform 0.4s ease-out;
        &:hover .sek1__icon{
            background-color: $color-main;
            fill: $color-extra;
        }
        &:hover{
            transform:translate(3px, -13px);
            @include mq($until: sm) {
                transform:translate(0px, -5px);
            }
        }
        @include mq($until: xl) {
            min-height: 390px;
        }
        @include mq($until: lg) {
            padding: 1vw 1vw ;
            min-height: 350px;
        }
        @include mq($until: md) {
            padding: 2vw 2vw ;
            min-height: 280px;
            border-radius: 13px 13px;
        }
        @include mq($until: xs) {
            min-height: 250px;
            padding: 18px 5vw 18px 5vw;  
            width: 86%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__title{
        font-size: rem(21px);
        color: $color-main;
        font-weight: 600;
        margin-bottom: 13px;
        @include mq($until: lg) {
            font-size: rem(19px);
            letter-spacing: -0.5px;
        }
    }
    &__icon{
        fill: #a0445b;
        background-color: #fbf7f1;
        width: 130px;
        height: 130px;
        border-radius: 90px 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 21px 0;
        box-shadow: inset 0 5px 10px rgba(0,0,0,0.08);
        transition:  background-color 0.3s ease-out, fill 0.3s ease-out ;
        svg{
            width: 5vw;
            @include mq($until: md) {
                width: 8vw;
            }
            @include mq($until: xs) {
                width: 65px;
            }
        }
        @include mq($until: xl) {
            width:110px;
            height: 110px;
        }
    }
    &__text{
        @include mq($until: xl) {
            font-size: rem(16px);
        }
    }
}

.sek2{
    // min-height: 140vh;
    height: 100vw;
    height: auto;
    position: relative;
    display: flex;
    @include mq($until: xs) {
        background-color: $color-extra;    
    }
    @include mq($until: md) {
        flex-direction: column-reverse;    
    }
    &__wrapper{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: 34vw;
        @include mq($until: xl) {
            margin-top: 35vw;
        }
        @include mq($until: lg) {
            margin-top: 42vw;
            align-items: center;
        }
        @include mq($until: md) {
            margin-top: 24vw;
        }
        // @include mq($until: sm) {
        //     flex-direction: column-reverse;
        //     margin-top: 18vw;
        // }
        @include mq($until: md) {
            margin-top: 10vw; 
            flex-direction: column;
            background-color: $color-extra;
        }
    }
    &__bg{
        top: 0px;
        z-index: -1;
        position: absolute;
        background-image: url('../../images/sek2-bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height:56vw;
        width: 100%;
        @include mq($until: xl) {
            height:66vw;
        }
        @include mq($until: lg) {
            height:76vw;
        }
        @include mq($until: md) {
            background-color:$color-extra;
            height:60vw;
        }
        @include mq($until: md) {
            z-index: 1;
        }
    }
    &__tooth{
        width: 53%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
       
        img{
            width: 32vw;
            @include mq($until: xl) {
                width: 33vw;
            }
            @include mq($until: md) {
                display: initial; 
                margin-bottom: 34px;
                width: 40vw;  
                z-index: 2; 
                margin-top:13vw;
            }
            @include mq($until: sm) {
                display: initial; 
                margin-bottom: 34px;
                width: 55vw;  
                z-index: 2; 
                margin-top:55px;
            }
        }
    }
    &__text{
        margin-bottom: 15vw;
        width: 47%;
        @include mq($until: xl) {
            margin-bottom: 10vw;
        }
        @include mq($until: lg) {
            width:70% ;
        }
        @include mq($until: md) {
            width: 100%;
            text-align: center; 
            justify-content: center;
            margin-bottom: 55px;  
        }

        p, h2{
            color: #fff;
            max-width: 75%;
            @include mq($until: xl) {
                min-width: 90%;
            }
            @include mq($until: lg) {
                width:100% ;
                p{
                    font-size: rem(16px);
                }
                h2{
                    font-size: rem(16px);
                }
            }
            @include mq($until: md) {
              width: 86%;
               margin-left: auto;
               margin-right: auto;
               color: $color-main;
            }
        }
    }
    &__button{
        display: inline-block;
        margin-top: 25px;
        background-color: #fff;
        padding: 18px 34px;
        width: auto;
        border-radius: 5px;
        font-weight: 600;
        box-shadow: 0px 0px 13px 5px rgba(0,0,0,0.03);
        transition: background-color 0.4s ease-out, color 0.4s ease-out;
       &:hover{
        background-color: $color-hover;
        color: #fff;
       }
    }
    &__bg2{
        position: absolute;
        z-index: -5;
        bottom: -180px;       
        svg{
            width: 100%;
        }
        @include mq($until: xl) {
            bottom: -230PX;
        }
        @include mq($until: lg) {
            bottom: -250px;
        }  
        @include mq($until: md) {
            bottom: -320px;
        } 
        @include mq($until: xs) {
            bottom: -360px; 
        } 
    }
}
.sek3{
    height:auto;
    max-height: 100vw;
    position: relative;
    z-index: 1;
    
    @include mq($until: xl) {
       height: 120vh;
    }
    @include mq($until: lg) {
        height:auto;
    }
    &__wrapper{
        display: flex;
        justify-content:space-around;
        align-items: center;
        @include mq($until: xl) {
            justify-content:space-between  ;
        }
        @include mq($until: xs) {
            flex-direction: column-reverse;    
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        @include mq($until: sm) {
           margin-bottom: 34px; 
        }
        @include mq($until: xs) {
            margin-bottom: 55px; 
         }

    }
    &__frau{
        margin-top: 16vw;
        @include mq($until: xl) {
            width: 50%;  
            margin-top: 13vw; 
        }
        @include mq($until: xs) {
            width: 84%; 
            margin-bottom: 21px;
            margin-top: 1vw;   
        }
    }
}
.map{
    height: auto;
    position: relative;

    @include mq($until: xs) {
        height: 40vw;    
    }
    &__shape{
        filter: grayscale(70%);
        svg{
            width: 100%;
            height: 80%;
        }   
        @include mq($until: xs) {
            margin-top: auto;    
        }
    }
    &__bg{
        position: absolute;
        top:-200px;
        z-index: -1;
        transform: scaleX(-1);
        width: 100%;
        height: auto;
        @include mq($until: xl) {
            top: -180px;
        }
        @include mq($until: lg) {
            top: -140PX;
        }
        @include mq($until: md) {
            top: -90px;
         }
        @include mq($until: sm) {
            top: -80px;
        }
        @include mq($until: xs) {
           position: relative;
           top: -150px;
        }
    }
}