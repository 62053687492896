.header{
    margin-top: 21px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    &--sub .header__menuLink{
           color: $color-main;
           transition: color 0.4s ease-out;
           &:hover{
               color: $color-hover;
           }
    }
    @include mq($until: lg) {
        position: relative;
        margin-top: 0;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
     }
    &--fixed{
      position: fixed;
      width: 100%;
      transition: background-color .3s ease-in-out;
      &.sticky{
        background-color: lemonchiffon;
      }
    }
    &__menuList{
        display: flex;
        justify-content: flex-end;
        align-items: center;  
    }
    &__menuItem{
        margin-left: 10px;
        padding: 0;
        &::before{
            display: none;
        }
    }
    &__menuLink{
        color: #fff;
        text-shadow: 2px 2px 4px rgba(0,0,0,0.10);
        font-weight: 600;
        font-size: rem(21px);
        @include mq($until: lg) {
            color: $color-main;
        }
        @include mq($until: md) {
            font-size: rem(28px);
        }
        &:active, &--active, &--open{
            color: $color-main;
        }
    }
    &__logo{
        @include mq($until: xs) {
            width: 230px;
         }
    }
    
}